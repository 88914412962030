import Hero from "./Hero";

const Licenses = () => {
  return (
    <>
      <Hero />
    </>
  );
};

export default Licenses;
