import cn from "classnames";
// import Card from "../../../components/Card";
import styles from "./gallery.module.css";
import { motion } from "framer-motion";
import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { getPlatforms } from "@ionic/react";
import Dropdown from "../../../components/Dropdown";

const gallery = [
  {
    title: "Elusive moments",
    galleries: [
      {
        src: "images/ElusiveMoments/The fishermen. Cascais,Portugal.October2022.jpg",
        width: 4,
        height: 6,
        title: "The fishermen. Portugal, Cascais. October 2022",
      },
      {
        src: "images/ElusiveMoments/Conversation.Odesa,Ukraine.November2021.jpg",
        width: 3.5,
        height: 5,
        title: "Conversation. Ukraine, Odesa. November 2021",
      },
      {
        src: "images/ElusiveMoments/70.Barcelona, Spain.December2022.jpg",
        width: 4,
        height: 6,
        title: "70.Barcelona. Spain. December 2022",
      },
      {
        src: "images/ElusiveMoments/New Places.Sintra,Portugal.February2023.jpg",
        width: 4.5,
        height: 3,
        title: "New Places. Sintra, Portugal. February 2023",
      },

      {
        src: "images/ElusiveMoments/Afternoon coffe. Ukraine.September2020.JPG",
        width: 6,
        height: 4,
        title: "Afternoon coffee. Ukraine. September 2020",
      },
      {
        src: "images/ElusiveMoments/The wall.Lviv, Ukraine.December2020.jpg",
        width: 4,
        height: 2.5,
        title: "The wall. Lviv, Ukraine. December 2020",
      },
      {
        src: "images/ElusiveMoments/TheBridge.Berlin.Germany.September2021.jpeg",
        width: 3.5,
        height: 5,
        title: "TheBridge. Berlin, Germany. September 2021",
      },
      {
        src: "images/ElusiveMoments/Pure morning. Corcubion, Spain.February2023.jpg",
        width: 3.5,
        height: 5,
        title: "Pure morning. Corcubion, Spain. February 2023",
      },
      {
        src: "images/ElusiveMoments/Warm afternoon.Ukraine.September 2020.JPG",
        width: 4,
        height: 2.5,
        title: "Warm afternoon. Ukraine. September 2020",
      },

      {
        src: "images/ElusiveMoments/Last lights.Germany.November2021.jpg",
        width: 4,
        height: 3,
        title: "Last lights. Germany. November 2021",
      },
      {
        src: "images/ElusiveMoments/Dunes. Cascais,Portugal. January 2023.jpg",
        width: 3.5,
        height: 5,
        title: "Dunes. Cascais, Portugal. January 2023",
      },
      {
        src: "images/ElusiveMoments/Eye.Kyiv, Ukraine.November2020.jpg",
        width: 4.5,
        height: 3,
        title: "Eye. Kyiv, Ukraine. November 2020",
      },
      {
        src: "images/ElusiveMoments/Friendship.Kyiv, Ukraine.November2020.jpg",
        width: 4.5,
        height: 3,
        title: "Friendship. Kyiv, Ukraine. November 2020",
      },
      {
        src: "images/ElusiveMoments/AfterSummer.UkraineSeptember2021.jpeg",
        width: 6,
        height: 4,
        title: "After Summer. Ukraine. September 2021",
      },
      {
        src: "images/ElusiveMoments/Desna.Ukraine.September2020.JPG",
        width: 4.5,
        height: 3,
        title: "Desna. Ukraine. September 2020",
      },
      {
        src: "images/ElusiveMoments/Sea series. Burgas,Bulgaria.August 2021.jpg",
        width: 4,
        height: 3,
        title: "Sea series. Burgas, Bulgaria. August 2021",
      },

      {
        src: "images/ElusiveMoments/LastWinter.Kaniv,Ukraine.February2022.jpg",
        width: 3.5,
        height: 5,
        title: "LastWinter. Kaniv, Ukraine. February 2022",
      },
      {
        src: "images/ElusiveMoments/Cocoa.Kyiv, Ukraine.February2022.jpg",
        width: 3.5,
        height: 5,
        title: "Cocoa. Kyiv, Ukraine. February 2022",
      },
      {
        src: "images/ElusiveMoments/Chill.Porto,Portugal.July2022.jpg",
        width: 4,
        height: 3,
        title: "Chill. Porto, Portugal. July 2022",
      },

      {
        src: "images/ElusiveMoments/Preferences. Kyiv, Ukraine.November 2020.jpg",
        width: 3.5,
        height: 5,
        title: "Preferences. Kyiv, Ukraine. November 2020",
      },

      {
        src: "images/ElusiveMoments/Sea series.Odesa, Ukraine.November2021.jpg",
        width: 4.5,
        height: 3,
        title: "Sea series. Odesa, Ukraine. November2021",
      },
      {
        src: "images/ElusiveMoments/HomeSweetHome.Kyiv, Ukraine.October2021.JPG",
        width: 4.5,
        height: 3,
        title: "HomeSweetHome. Kyiv, Ukraine. October 2021",
      },
      {
        src: "images/ElusiveMoments/Tea Ceremony.Carpatians, Ukraine.February2021.jpg",
        width: 4,
        height: 2.7,
        title: "Tea Ceremony. Carpatians, Ukraine. February 2021",
      },

      {
        src: "images/ElusiveMoments/Trampoline set. Kyiv,Ukraine.March2021.jpg",
        width: 3.5,
        height: 5,
        title: "Trampoline set. Kyiv, Ukraine. March 2021",
      },
    ],
  },
  {
    title: "Come rain",
    galleries: [
      {
        src: "images/ComeRain/1.A girl with umbrella.Kyiv,Ukraine.July2021.jpg",
        width: 4,
        height: 2.5,
        title: "A girl with umbrella. Ukraine, Kyiv. July 2021",
      },
      {
        src: "images/ComeRain/2.Evening Walk. Kyiv,Ukraine.July2021.jpeg",
        width: 3.5,
        height: 5,
        title: "Evening Walk. Ukraine, Kyiv. July 2021",
      },
      {
        src: "images/ComeRain/3.A girl with umbrella2.Kyiv,Ukraine.July2021.jpg",
        width: 4,
        height: 2.5,
        title: "A girl with umbrella 2. Ukraine, Kyiv. July 2021",
      },
      {
        src: "images/ComeRain/4.Cold morning2.Carpatians,Ukrain.February2021.jpg",
        width: 4,
        height: 2.5,
        title: "Cold morning. Ukraine, Carpatians. February 2021",
      },

      {
        src: "images/ComeRain/5.Come rain.Cascais, PortugalOctober2022..jpg",
        width: 3.5,
        height: 5,
        title: "Come rain. Portugal, Cascais. October 2022",
      },
      {
        src: "images/ComeRain/6.Car rain.Kyiv,Ukraine.July2021.jpg",
        width: 4,
        height: 3,
        title: "Car rain. Ukraine, Kyiv. July 2021",
      },
      {
        src: "images/ComeRain/8.StopTheWar2.Lisbon,Portugal.October2022.jpg",
        width: 4,
        height: 3,
        title: "StopTheWar 2. Portugal, Lisbon. October 2022",
      },

      {
        src: "images/ComeRain/7.StopTheWar.Lisbon,Portugal.October2022.jpg",
        width: 3.5,
        height: 5,
        title: "StopTheWar. Portugal, Lisbon. October 2022",
      },

      {
        src: "images/ComeRain/11.StopTheWar3.Lisbon,Portugal.October2022.jpg",
        width: 3.2,
        height: 5,
        title: "StopTheWar 3. Portugal, Lisbon. October 2022",
      },
      {
        src: "images/ComeRain/9.Cold morning.Carpatians,Ukrain.February2021.jpg",
        width: 4.5,
        height: 3,
        title: "Cold morning 2. Carpatians, Ukraine. February 2021",
      },
      {
        src: "images/ComeRain/10.Cold morning3.Carpatians,Ukrain.February2021.jpg",
        width: 4,
        height: 3,
        title: "Cold morning 3. Carpatians, Ukraine. February 2021",
      },
      {
        src: "images/ComeRain/13.Summer weather. Budapest,Hungary.June2022.jpg",
        width: 3.2,
        height: 5,
        title: "Summer weather. Hungary, Budapest. June 2022",
      },

      {
        src: "images/ComeRain/12.The route.Lviv,Ukraine.June2021.jpg",
        width: 4,
        height: 2.5,
        title: "The route. Lviv, Ukraine. June 2021",
      },
    ],
  },
  {
    title: "Late night",
    galleries: [
      {
        src: "images/LateNight/A girl and a night view.Kyiv,Ukraine.December2020...jpg",
        width: 4.5,
        height: 3,
        title: "A girl and a night view. Ukraine, Kyiv. December 2020",
      },
      {
        src: "images/LateNight/A girl and a night view.Kyiv,Ukraine.December2020.jpg",
        width: 3.5,
        height: 5,
        title: "A girl and a night view. Ukraine, Kyiv. December 2020",
      },

      {
        src: "images/LateNight/Bar magic.Kyiv, Ukraine.January2022.jpg",
        width: 4,
        height: 2.5,
        title: "Bar magic. Ukraine, Kyiv. January 2022",
      },
      {
        src: "images/LateNight/Bar Magic.Kyiv,Ukraine.March2021.jpg",
        width: 4,
        height: 2.5,
        title: "Bar magic. Ukraine, Kyiv. March 2021",
      },
      {
        src: "images/LateNight/Bar magic.Lviv, Ukraine.June2022.jpg",
        width: 4,
        height: 2.5,
        title: "Bar magic. Ukraine, Lviv. June 2021",
      },
      {
        src: "images/LateNight/Bar magic2.Lviv, Ukraine.June2022.JPG",
        width: 4,
        height: 3,
        title: "Bar magic 2. Ukraine, Lviv. June 2022",
      },
      {
        src: "images/LateNight/Bar magic3.Lviv, Ukraine.June2022.jpg",
        width: 3.5,
        height: 5,
        title: "Bar magic 3. Ukraine, Lviv. June 2022",
      },
      {
        src: "images/LateNight/Beatnik.Kyiv, Ukraine.October2021.JPG",
        width: 3.5,
        height: 5,
        title: "Beatnik. Ukraine, Kyiv. October 2021",
      },
      {
        src: "images/LateNight/Bar magic.Kyiv, Ukraine.October2021...JPG",
        width: 3.5,
        height: 5,
        title: "Bar magic. Ukraine, Kyiv. October 2022",
      },
      {
        src: "images/LateNight/Bar magic4.Lviv, Ukraine.June2022.jpg",
        width: 3.5,
        height: 5,
        title: "Bar magic 4. Ukraine, Lviv. June 2022",
      },

      {
        src: "images/LateNight/Choose your planet.Kyiv sea,Ukraine.August2022..jpg",
        width: 4,
        height: 2.5,
        title: "Choose your planet. Ukraine, Kyiv sea. August 2022",
      },
      {
        src: "images/LateNight/Choose your planet.Kyiv sea,Ukraine.August2022.jpg",
        width: 3.5,
        height: 5,
        title: "Choose your planet. Ukraine, Kyiv sea. August 2022",
      },
      {
        src: "images/LateNight/Kyiv night.Kyiv,Ukraine.April2021.jpg",
        width: 3.5,
        height: 5,
        title: "Kyiv night. Ukraine, Kyiv. April 2021",
      },

      {
        src: "images/LateNight/Copperheads.Kyiv, Ukraine.November2021....jpg",
        width: 4.5,
        height: 3,
        title: "Copperheads. Ukraine, Kyiv. November 2021",
      },
      {
        src: "images/LateNight/Copperheads.Kyiv, Ukraine.November2021..jpg",
        width: 3.5,
        height: 5,
        title: "Copperheads. Ukraine, Kyiv. November 2021",
      },
      {
        src: "images/LateNight/Copperheads.Kyiv, Ukraine.November2021...jpg",
        width: 3.5,
        height: 5,
        title: "Copperheads. Ukraine, Kyiv. November 2021",
      },
      {
        src: "images/LateNight/Hendricks.Kyiv,Ukraine.February2021.jpg",
        width: 3.5,
        height: 5,
        title: "Hendricks. Ukraine, Kyiv. February 2021",
      },
      {
        src: "images/LateNight/Reflection.Kyiv,Ukraine.June2021..jpg",
        width: 4.5,
        height: 3,
        title: "Reflection. Ukraine, Kyiv. June 2021",
      },
      {
        src: "images/LateNight/Winter night.Kyiv,Ukraine.December2021.jpg",
        width: 4.5,
        height: 3,
        title: "Winter night. Ukraine, Kyiv. December 2021.jpg",
      },
      {
        src: "images/LateNight/Reflection.Kyiv,Ukraine.June2021.jpg",
        width: 3.5,
        height: 5,
        title: "Reflection. Ukraine, Kyiv. June 2021",
      },

      {
        src: "images/LateNight/Riders.Kyiv,Ukraine.April2021..jpg",
        width: 4.5,
        height: 3,
        title: "Riders. Ukraine, Kyiv. April 2021",
      },
      {
        src: "images/LateNight/Riders.Kyiv,Ukraine.April2021.jpg",
        width: 4.5,
        height: 3,
        title: "Riders. Ukraine, Kyiv. April 2021",
      },
    ],
  },
  {
    title: "Natural",
    galleries: [
      {
        src: "images/Natural/Big waves season.Nazare,Portugal.February2023..jpg",
        width: 5,
        height: 3.5,
        title: "Big waves season. Portugal, Nazare. February 2023",
      },
      {
        src: "images/Natural/Big waves season.Nazare,Portugal.February2023.jpg",
        width: 5,
        height: 3.5,
        title: "Big waves season. Portugal, Nazare. February 2023",
      },
      {
        src: "images/Natural/New places.Sintra,Portugal.February2023........jpg",
        width: 5,
        height: 3.5,
        title: "New places. Portugal, Sintra. February 2023",
      },
      {
        src: "images/Natural/New places.Sintra,Portugal.February2023......jpg",
        width: 3.5,
        height: 5,
        title: "New places. Portugal, Sintra. February 2023",
      },
      {
        src: "images/Natural/New places.Cabo da Roca,Portugal.June2022.jpg",
        width: 5,
        height: 3.5,
        title: "New places. Portugal, Cabo da Roca. June 2022",
      },
      {
        src: "images/Natural/New places.Cabo daRoca,Portugal.November2022.jpg",
        width: 5,
        height: 3.5,
        title: "New places. Portugal, Cabo da Roca. November 2022",
      },
      {
        src: "images/Natural/New places.Sintra,Portugal.February2023....jpg",
        width: 4,
        height: 2.5,
        title: "New places. Portugal, Sintra. February 2023",
      },
      {
        src: "images/Natural/New places.Sintra,Portugal.February2023..jpg",
        width: 5,
        height: 3.5,
        title: "New places. Portugal,Sintra. February 2023",
      },
      {
        src: "images/Natural/New places.Sintra,Portugal.February2023.jpg",
        width: 4,
        height: 2.5,
        title: "New places. Portugal, Sintra. February 2023",
      },

      {
        src: "images/Natural/Road on series.Austria.January2022.jpg",
        width: 5,
        height: 3.5,
        title: "Road on series. Austria. January 2022",
      },
      {
        src: "images/Natural/Road on series.Filzback, Switzerland.January2022.jpg",
        width: 5,
        height: 3.5,
        title: "Road on series. Switzerland, Filzback. January 2022",
      },
      {
        src: "images/Natural/Sea series.Odesa,Ukrain.October2021 .jpg",
        width: 3.5,
        height: 5,
        title: "Sea series. Odesa, Ukraine. October 2021",
      },
      {
        src: "images/Natural/Sea series.Odesa,Ukrain.October2021.....jpg",
        width: 4,
        height: 2.5,
        title: "Sea series. Odesa, Ukraine. October 2021",
      },
      {
        src: "images/Natural/It_s time.Odesa,Ukrain.October2021..jpg",
        width: 5,
        height: 3.5,
        title: "It_s time. Odesa, Ukraine. October 2021",
      },
      {
        src: "images/Natural/It_s time.Odesa,Ukrain.October2021.jpg",
        width: 5,
        height: 3.5,
        title: "It_s time. Odesa, Ukraine. October 2021",
      },
      {
        src: "images/Natural/Sea series.Odesa,Ukrain.October2021....jpg",
        width: 4,
        height: 2.5,
        title: "Sea series. Odesa, Ukraine. October 2021",
      },
      {
        src: "images/Natural/Sea series.Odesa,Ukrain.October2021...jpg",
        width: 3.5,
        height: 5,
        title: "Sea series. Odesa, Ukraine. October 2021",
      },
      {
        src: "images/Natural/Sea series.Odesa,Ukrain.October2021..jpg",
        width: 4,
        height: 2.5,
        title: "Sea series. Odesa, Ukraine. October 2021",
      },
      {
        src: "images/Natural/Stratching.Odesa,Ukrain.October2021.jpg",
        width: 4,
        height: 2.5,
        title: "Stratching. Odesa, Ukraine. October 2021",
      },
    ],
  },
  {
    title: "Patterns",
    galleries: [
      {
        src: "images/Pattern/1.jpg",
        width: 5,
        height: 3.5,
        title: "Autumn. Kyiv, Ukraine. October 2021",
      },
      {
        src: "images/Pattern/2.jpg",
        width: 3,
        height: 5,
        title: "Autumn. Kyiv, Ukraine. October 2021",
      },
      {
        src: "images/Pattern/04.jpg",
        width: 5,
        height: 3.5,
        title: "Textures. Cabo da Roca, Portugal. July 2022",
      },
      {
        src: "images/Pattern/33.jpg",
        width: 4,
        height: 2.5,
        title: "Space. Odesa, Ukraine. October 2021.",
      },
      {
        src: "images/Pattern/36.jpg",
        width: 5,
        height: 3.5,
        title: "Space. Odesa, Ukraine. October 2021.",
      },
      {
        src: "images/Pattern/38.jpg",
        width: 3.5,
        height: 5,
        title: "Space. Odesa, Ukraine. October 2021.",
      },
      {
        src: "images/Pattern/89FE2928-8280-4803-84EC-A7E0930A9B39.JPG",
        width: 3.5,
        height: 5,
        title: "Boundaries. Parede, Portugal. January 2023",
      },
      {
        src: "images/Pattern/DSC05982_01.jpg",
        width: 5,
        height: 3.5,
        title: "Bookshelf. Lviv, Ukraine. December2020",
      },
      {
        src: "images/Pattern/DSC06110.jpg",
        width: 5,
        height: 3.5,
        title: "Fern. Desna, Ukraine. June 2021",
      },
      {
        src: "images/Pattern/DSC08547.jpg",
        width: 5,
        height: 3.5,
        title: "Winter Forest. Kyiv, Ukraine. January 2021",
      },
      {
        src: "images/Pattern/DSC08616.jpg",
        width: 8,
        height: 4.5,
        title: "Winter Forest. Kyiv, Ukraine. January 2021",
      },
      {
        src: "images/Pattern/WinterForest.Kyiv,Ukraine.January2021...jpg",
        width: 5,
        height: 3.5,
        title: "Winter Forest. Kyiv, Ukraine. January 2021",
      },
      {
        src: "images/Pattern/Ice Forest. Kyiv, Ukraine. December2021..JPG",
        width: 3,
        height: 4.5,
        title: "Ice Forest. Kyiv, Ukraine. December 2021",
      },
      {
        src: "images/Pattern/Cold.Ukraine.September2021..jpg",
        width: 5,
        height: 3.5,
        title: "Cold. Ukraine. September 2021.",
      },
      {
        src: "images/Pattern/Cold.Ukraine.September2021.jpg",
        width: 5,
        height: 3.5,
        title: "Cold. Ukraine. September 2021.",
      },
      {
        src: "images/Pattern/Ice Forest. Kyiv, Ukraine. December2021....JPG",
        width: 3,
        height: 4.5,
        title: "Ice Forest. Kyiv, Ukraine. December 2021",
      },
      {
        src: "images/Pattern/Ice Forest. Kyiv, Ukraine. December2021...JPG",
        width: 3,
        height: 4.5,
        title: "Ice Forest. Kyiv, Ukraine. December 2021",
      },

      {
        src: "images/Pattern/Ice Forest. Kyiv, Ukraine. December2021.JPG",
        width: 3,
        height: 4.5,
        title: "Ice Forest. Kyiv, Ukraine. December 2021",
      },
      {
        src: "images/Pattern/Madeira patterns. Madeira, Portugal.January2023.jpg",
        width: 4.5,
        height: 3,
        title: "Madeira patterns. Madeira, Portugal. January 2023",
      },
      {
        src: "images/Pattern/Madeira patterns2.Madeira,Portugal.January2023.jpg",
        width: 4.5,
        height: 3,
        title: "Madeira patterns. Madeira, Portugal. January 2023",
      },
      {
        src: "images/Pattern/Madeira patterns3.Madeira,Portugal.January2023.jpg",
        width: 5,
        height: 3,
        title: "Madeira patterns. Madeira, Portugal. January 2023",
      },
      {
        src: "images/Pattern/Take your time.Barcelona, Spain.December2022.jpg",
        width: 5,
        height: 3,
        title: "Take your time. Barcelona, Spain. December 2022",
      },
    ],
  },
  {
    title: "Urban",
    galleries: [
      {
        src: "images/Urban/1.jpg",
        width: 3.5,
        height: 5,
        title: "Unclear Future. Romania. March2022",
      },
      {
        src: "images/Urban/2.jpg",
        width: 3.5,
        height: 5,
        title: "Unclear Future. Romania. March2022",
      },
      {
        src: "images/Urban/3.jpg",
        width: 3.5,
        height: 5,
        title: "MAAT. Lisbon, Portugal. July2022",
      },
      {
        src: "images/Urban/4.jpg",
        width: 5,
        height: 3.5,
        title: "Evening Line. Lisbon, Portugal. June 2022",
      },
      {
        src: "images/Urban/5.jpg",
        width: 5,
        height: 3.5,
        title: "Fary. Lisbon, Portugal. July2022",
      },
      {
        src: "images/Urban/6.jpg",
        width: 3.5,
        height: 5,
        title: "Invincible. Kyiv, Ukraine. August 2022",
      },

      {
        src: "images/Urban/8.jpg",
        width: 5,
        height: 3.5,
        title: "Invincible. Kyiv, Ukraine. August 2022",
      },
      {
        src: "images/Urban/9.jpg",
        width: 5,
        height: 3.5,
        title: "Invincible. Kyiv, Ukraine. August 2022",
      },
      {
        src: "images/Urban/7.jpg",
        width: 3.5,
        height: 5,
        title: "Spanish streets. Barcelona, Spain. December 2022",
      },
      {
        src: "images/Urban/10.jpg",
        width: 5,
        height: 3.3,
        title: "Spanish streets. Barcelona, Spain. December 2022",
      },
      {
        src: "images/Urban/11.jpg",
        width: 5,
        height: 3.3,
        title: "Crowdy. Nesebar, Bolgaria. October 2021",
      },
      {
        src: "images/Urban/12.jpg",
        width: 5,
        height: 3.5,
        title: "Window. Nesebar, Bolgaria. October 2021",
      },
      {
        src: "images/Urban/13.jpg",
        width: 3.5,
        height: 5,
        title: "Nesebar, Bolgaria. October 2021",
      },
      {
        src: "images/Urban/14.jpg",
        width: 5,
        height: 3.5,
        title: "Podil. Kyiv, Ukraine. October 2020",
      },
      {
        src: "images/Urban/36.jpg",
        width: 5,
        height: 3.5,
        title: "Podil. Kyiv, Ukraine. October 2020",
      },
      {
        src: "images/Urban/37.jpg",
        width: 5,
        height: 3.5,
        title: "Cais. Lisbon, Portugal. July 2022",
      },
      {
        src: "images/Urban/101.jpg",
        width: 5,
        height: 4,
        title: "Lisbon Streets. Lisbon, Portugal. October 2022",
      },
      {
        src: "images/Urban/102.jpg",
        width: 5.5,
        height: 3.5,
        title: "Lisbon Streets. Lisbon, Portugal. October 2022",
      },
      {
        src: "images/Urban/103.jpg",
        width: 5.5,
        height: 4,
        title: "Lisbon Streets. Lisbon, Portugal. October 2022",
      },
      {
        src: "images/Urban/104.jpg",
        width: 5.5,
        height: 4,
        title: "Lisbon Streets. Lisbon, Portugal. October 2022",
      },
      {
        src: "images/Urban/105.jpg",
        width: 5.5,
        height: 4,
        title: "Lisbon Streets. Lisbon, Portugal. October 2022",
      },
      {
        src: "images/Urban/106.jpg",
        width: 5,
        height: 4,
        title: "Lisbon Streets. Lisbon, Portugal. October 2022",
      },
      {
        src: "images/Urban/107.jpg",
        width: 5,
        height: 4,
        title: "Lisbon Streets. Lisbon, Portugal. October 2022",
      },
      {
        src: "images/Urban/108.jpg",
        width: 5,
        height: 4,
        title: "Lisbon Streets. Lisbon, Portugal. October 2022",
      },
      {
        src: "images/Urban/109.jpg",
        width: 5,
        height: 4,
        title: "Lisbon Streets. Lisbon, Portugal. October 2022",
      },
      {
        src: "images/Urban/110.jpg",
        width: 5,
        height: 4,
        title: "Lisbon Streets. Lisbon, Portugal. October 2022",
      },
      {
        src: "images/Urban/124.jpg",
        width: 3.5,
        height: 5,
        title: "Chill. Porto, Portugal. July 2022.",
      },
      {
        src: "images/Urban/125.jpeg",
        width: 5,
        height: 3.5,
        title: "Protest. Brussels, Belgium. March 2022",
      },
      {
        src: "images/Urban/125.jpg",
        width: 5,
        height: 3.5,
        title: "Dragon. Kyiv, Ukraine. November 2020",
      },
      {
        src: "images/Urban/127.jpg",
        width: 5,
        height: 3.5,
        title: "Protest. Amsterdam, Netherlands. April 2022",
      },
      {
        src: "images/Urban/128.jpg",
        width: 5,
        height: 3.5,
        title: "Early Sunset. Kyiv,Ukraine. January 2021",
      },
      {
        src: "images/Urban/129.jpg",
        width: 5,
        height: 3.5,
        title: "Clowdy. Prague,CzechRepublic June 2022",
      },
      {
        src: "images/Urban/130.jpg",
        width: 5,
        height: 3.5,
        title: "Clowdy. Prague, CzechRepublic June 2022",
      },
      {
        src: "images/Urban/131.jpg",
        width: 3.5,
        height: 5,
        title: "Clowdy. Prague, CzechRepublic June 2022",
      },
      {
        src: "images/Urban/132.jpg",
        width: 3.5,
        height: 5,
        title: "Maat. Lisbon, Portugal. January 2023",
      },
      {
        src: "images/Urban/133.JPEG",
        width: 3.5,
        height: 5,
        title: "Hub. Berlin, Germany. October 2021",
      },
      {
        src: "images/Urban/134.jpg",
        width: 5,
        height: 3.5,
        title: "Lisbon Streets. Lisbon, Portugal. June 2022",
      },
      {
        src: "images/Urban/135.jpg",
        width: 3.5,
        height: 5,
        title: "New Places. Sintra, Portugal. Februar 2023",
      },
      {
        src: "images/Urban/Birds.Berlin,Germany.September2021.jpg",
        width: 5,
        height: 3.5,
        title: "Birds. Berlin, Germany. September 2021",
      },
      {
        src: "images/Urban/BerlinNight.Berlin,Germany.September2021..jpg",
        width: 3.5,
        height: 5,
        title: "Berlin Night. Berlin, Germany. September 2021",
      },
      {
        src: "images/Urban/BerlinNight.Berlin,Germany.September2021.jpg",
        width: 3.5,
        height: 5,
        title: "Berlin Night. Berlin, Germany. September 2021",
      },
      {
        src: "images/Urban/The moon.Berlin,Germany.September2021.jpg",
        width: 3.5,
        height: 5,
        title: "The moon. Berlin,Germany. September 2021",
      },
      {
        src: "images/Urban/First.Kyiv,Ukraine.September2020.JPG",
        width: 5,
        height: 3.5,
        title: "First. Kyiv,Ukraine. September 2020",
      },
      {
        src: "images/Urban/Danzig.Gdansk,Poland.December2021.jpg",
        width: 3.5,
        height: 5,
        title: "Danzig. Gdansk, Poland. December 2021",
      },
      {
        src: "images/Urban/Danzig.Gdansk,Poland.December2021..JPG",
        width: 3.5,
        height: 5,
        title: "Danzig. Gdansk, Poland. December 2021",
      },
      {
        src: "images/Urban/BerlinStreet.Berlin,Germany.September2021.jpg",
        width: 3.5,
        height: 5,
        title: "Berlin Street. Berlin, Germany. September 2021",
      },

      {
        src: "images/Urban/Year.Lisbon,Portugal.February2023..jpg",
        width: 5,
        height: 3.5,
        title: "Year. Lisbon,Portugal. February 2023",
      },
      {
        src: "images/Urban/Year.Lisbon,Portugal.February2023.jpg",
        width: 5,
        height: 3.5,
        title: "Year. Lisbon,Portugal. February 2023",
      },
    ],
  },
];

export default function PhotoGallery() {
  const options = [];
  gallery.map((item) => options.push(item.title));

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [platform] = useState(getPlatforms());

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const [direction, setDirection] = useState(options[0]);

  return (
    <motion.section className={styles.section}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <div className={styles.nav}>
            {gallery.map((item, index) => (
              <button
                className={cn(styles.button, {
                  [styles.active]: item.title === direction,
                })}
                onClick={() => setDirection(item.title)}
                key={index}
              >
                {item.title}
              </button>
            ))}
          </div>

          <Dropdown
            className={styles.dropdown}
            value={direction}
            options={options}
            setValue={setDirection}
          />
        </div>

        <div className={styles.wrapper}>
          <Gallery
            photos={gallery.find((item) => item.title === direction).galleries}
            onClick={openLightbox}
            targetRowHeight={platform[0] === "desktop" ? 500 : 300}
          />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={gallery
                    .find((item) => item.title === direction)
                    .galleries.map((x) => ({
                      ...x,
                      srcset: x.srcSet,
                      caption: x.title,
                    }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      </div>
    </motion.section>
  );
}
