import Hero from "./Hero";

const Changelog = () => {
  return (
    <>
      <Hero />
    </>
  );
};

export default Changelog;
