import Hero from '../Home/Hero';
import PhotoGallery from './Gallery';

const Home = () => {
  return (
    <>
      <Hero />
      <PhotoGallery />
    </>
  );
};

export default Home;
